.pad-t80 {
  padding-top: 50px;
}
.footer-section {
  background: #fde6e7;
}

.Footer-part {
  margin: 0 50px;
}

@media only screen and (min-width: 1910px) {
  .Footer-part {
    padding: 40px 18rem;
    margin: 0px;
  }
}

.footer-title h3 {
  font-size: 20px;
  text-align: left;
  color: #eb3592;
  margin-bottom: 20px;
  font-family: "Open Sans";
  text-transform: uppercase;
  font-weight: 700;
}

.footer-text p {
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 24px;
}
.footer-office-hour {
  margin-bottom: 30px;
}
.footer-office-hour ul li:first-child a {
  color: black;
  padding-left: 4px;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.footer-office-hour ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  /* letter-spacing: 1px; */
  padding: 2px 0 2px 4px;
  display: block;
  font-family: "Open Sans";
  line-height: 24px;
  cursor: default;
}

.footer-office-hour ul li p {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  /* letter-spacing: 1px; */
  padding: 2px 0 2px 4px;
  display: block;
  font-family: "Open Sans";
  line-height: 24px;
}
.footer-list {
  margin-bottom: 30px;
}
.footer-list ul li a {
  color: #000000;
  font-size: 17px;
  font-weight: 600;
  display: block;
  padding: 5px 0;
  text-decoration: none;
  font-family: "Open Sans";
}
.t1 {
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
}
.footer-subscribe button {
  position: absolute;
  right: 0;
  top: 0;
  background: #eb3592;
  width: 60px;
  height: 46%;
  margin-top: 6px;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  outline: none;
}

@media only screen and (max-width: 767px) {
  .footer-subscribe button {
    height: 46%;
  }
}
.top-header-social li:first-child {
  margin-left: 0px;
}
.top-header-social li {
  font-size: 25px;
  line-height: 16px;
  display: inline-block;
  margin: 7px 1px 0 0;
  padding: 10px 10px;
  list-style: none;
  text-align: center;
}
.top-header-social li a i {
  color: #000000;
  text-decoration: none;
  transition: transform 300ms ease-in-out;
}

.top-header-social li:hover a .fb {
  color: #3b5998;
  transform: scale(1.2);
}

.top-header-social li:hover a .insta {
  color: #eb3592;
  transform: scale(1.2);
}
.top-header-social li:hover a .yt {
  color: #cd201f;
  transform: scale(1.2);
}
.top-header-social li:hover a .tw {
  color: #00acee;
  transform: scale(1.2);
}
.copyright {
  border-top: 1px solid #353535;
  padding: 25px 0;
}
.copyright-text p {
  color: white;
}
.copyright-text a {
  color: Orange;
}
.footer-menu ul {
  text-align: right;
}
.footer-menu ul li {
  display: inline-block;
}
.footer-menu ul li a {
  color: #bfbfbf;
  font-size: 16px;
  display: inline-block;
  padding-left: 25px;
  text-decoration: none;
}
.footer-subb {
  padding-bottom: 30px;
  font-family: "Open Sans";
}

.share {
  background: #fafafa;
  height: 50px;
  width: 280px;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 0 20px rgba(0.2, 0, 0.2, 0.1);
  border: none;
  font-family: "Open Sans";
}
/* .share:hover{
  transform: scale(1.1);
} */
.share span {
  position: absolute;
  background: linear-gradient(90deg, #ed3592, #f670b8 51%, #ed3592) !important;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  transition: 0.3s linear;
  z-index: 90;
  border-radius: 40px;
}
.share span .fa-share-alt {
  margin-right: 5px;
  font-size: 16px;
}
.share:hover span {
  transform: translateX(-100%);
}
.share a {
  margin: 5px;
}
.share a:hover {
  transform: translateY(-10%);
}

.footer-arrow {
}

.footer-link12:hover .footer-arrow {
  animation-name: shake-hover;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  background-color: #eb3592;
  color: white;
  border-radius: 30px;
  padding: 3px 3px;
}

@keyframes shake-hover {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-8px);
  }
  20% {
    transform: translateX(6px);
  }
  30% {
    transform: translateX(-4px);
  }
  40% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-2px);
  }
  60% {
    transform: translateX(1px);
  }
  70% {
    transform: translateX(-1px);
  }
  80% {
    transform: translateX(0.5px);
  }
  90% {
    transform: translateX(-0.5px);
  }
  100% {
    transform: translateX(0);
  }
}
