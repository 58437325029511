.img-fluid {
  height: 100% !important;
  background-size: cover;
}

.see-more-icon {
  font-size: 28px;
}

.specials-section {
  padding: 0px 50px;
  background: #fef2f3;
}

@media only screen and (min-width: 1910px) {
  .specials-section {
    padding: 0px 18rem;
  }
}
.card-title {
  color: rgb(0, 0, 0);
  font-size: 22px;
  line-height: 100%;
  text-align: left;
  line-height: inherit;
  margin-bottom: 5px !important;
  display: block;
  position: relative;
  text-transform: uppercase;
  font-family: "Open Sans";
  margin-top: 2px;
  font-weight: bold;
}
.card-text {
  font-size: 14px;
  color: #000000;
  font-family: "Open Sans";

  font-weight: 400;
  line-height: 23px;
}
.see-more-btn {
  background-color: white;
  align-items: center;
  display: inline-flex;
  /* margin: auto; */
  /* margin-left: auto; */
  margin-left: 14%;
  color: #eb3592;
  /* margin-bottom: -61px; */
  border: 2px #eb3592 solid;
  border-radius: 25px;
  padding: 6px 50px;
  margin-top: -12px !important;
  z-index: 2;
  position: relative;
  font-family: "Open Sans";
  text-transform: uppercase;
  font-weight: 600;
}

.blog-item:hover .see-more-btn {
  background-color: #eb3592;
  color: white;
}

.blog-item:hover .see-more-btn .see-more-icon {
  transform: scale(1.2);
}

.blog-item:hover .see-more-btn {
  animation-name: shake-hover1;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes shake-hover1 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-8px);
  }
  20% {
    transform: translateY(6px);
  }
  30% {
    transform: translateY(-4px);
  }
  40% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(1px);
  }
  70% {
    transform: translateY(-1px);
  }
  80% {
    transform: translateY(0.5px);
  }
  90% {
    transform: translateY(-0.5px);
  }
  100% {
    transform: translateY(0);
  }
}

.vegan {
  position: relative;
  width: 5%;
  right: 40px;

  z-index: 2;
}
.battuu {
  background: #ffa500fa;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none !important;
  border: 1px solid transparent;
  padding: 0.45rem 0.75rem;

  font-size: 1rem;
  margin-bottom: 15px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .battuu {
    padding: 0.05rem 0.75rem;
    display: none;
  }
}
.btn-primary {
  background: #eb3592 !important;
  border: 2px solid transparent !important;
}
.btn-primary:hover {
  background: #eb3593e6 !important;
  transform: scale(0.9);
  border: 2px solid #023047 !important;
  transition: 10ms ease-in-out;
}
.btn-primary:focus {
  outline: none;
  box-shadow: none !important;
}

.btn-primary:hover .fa {
  color: #023047;
}

.Special-head {
  font-weight: bold;
  color: #eb3592;
  font-family: "Open Sans" !important;
  text-align: left;
  font-size: 28px;
  font-weight: 700 !important;
  line-height: 56px;
  letter-spacing: -1px;
}

.card {
  border-radius: 10px;
}

.card1 {
  border-radius: 35px !important ;
  height: 100%;
  margin-bottom: -16px;
}

.card-body1 {
  background: linear-gradient(
    to bottom,
    #eb3592a3 3%,
    #f46197b0 13%,
    #fef2f3 59%,
    #fde6e7 89%
  );
  color: white;
  color: white;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.card img {
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  height: 450px !important;
  width: 100%;
}

.card-part {
  margin-bottom: 20px;
}

.roll-special {
  width: 80%;
}

.roll-special1 {
  width: 20%;
}

@media only screen and (max-width: 767px) {
  .ProductsContainer-small-screen {
    margin-top: 60px;
  }
  .Special-head {
    /* margin-top: -20px; */
    font-size: 25px;
  }
  .specials-section {
    /* margin-top: 60px; */
  }
  .card-part {
    margin-bottom: 45px;
  }
  .roll-special {
    width: 100%;
  }
}

.blog-item {
  cursor: pointer !important;
}

.blog-item-image-div {
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 35px !important;
  border-top-left-radius: 35px !important;
}

.blog-item:hover .blog-image {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}
.blog-item-image-div .blog-image {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  width: 100%;
}
