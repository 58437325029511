.Containerr {
  align-items: center;
  padding: 25px;
  background-color: #ffffff;
  padding-bottom: 3%;
}

@media only screen and (min-width: 1910px) {
  .Containerr {
    padding: 40px 18rem;
  }
}

@media only screen and (max-width: 767px) {
  .Containerr {
    padding-bottom: 7%;
  }
}

.padding {
  text-align: center;
  font-size: 5rem;
  font-family: "Open Sans";
}
.insta-head-link {
  color: #ed3592;
}
.insta-head-link:hover {
  color: orange;
  text-decoration: none;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(240, 248, 255, 0.684);
  transition: all 0.2s linear;
}

#imggg:hover .overlay {
  opacity: 1;
}

.text {
  color: rgb(15, 1, 1);
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.imagee {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
@media only screen and (max-width: 900px) {
  .imagee {
    padding: 1%;
    padding-bottom: 2%;
  }
}

.eating {
  padding-left: 33%;
}
@media only screen and (max-width: 900px) {
  .eating {
    padding: 1%;
  }
}
