/* .marquee-kk {
  padding: 5px 10px;
  border-radius: 30px;
  box-shadow: 0px 0px 5px grey;
  margin: 20px 10px;
  display: inline-block;
}

.marquee-part {
  padding: 80px 40px;
}

.marquee-container {
  overflow: hidden;
  width: 100%;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-kk {
  padding: 0 10px;
} */
.marquee-container {
  overflow: hidden;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 40s linear infinite;
}

.marquee-content2 {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

.marquee-content3 {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
}

.marquee-content4 {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 40s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee-item {
  border-radius: 30px;
  box-shadow: 0px 0px 5px 0px #80808094;
  margin: 10px;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

.marquee-item:hover {
  transform: scale(1.05);
  background-color: #fef2f3;
  box-shadow: 0px 0px 10px 0px #80808094;
}

.marquee-container {
  position: relative;
  overflow: hidden;
  padding: 60px;
  padding-top: 40px;
}

.marquee-content {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee-item {
  padding: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: red;
  line-height: 160%;
  /* background: linear-gradient(359deg, #fe4e4ec7, #ff983b00, #00c4d400, #6344903d, #ee5397); */
  font-family: "Open Sans";
}

.marquee-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 180px 0 100px -26px #fff, inset -180px 0 100px -26px #fff;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .marquee-overlay {
    box-shadow: none;
  }
}
