.forbgclr {
    background-color: #f8f7f5;
    justify-content: space-between;
    flex-wrap: wrap;
}

.kungfu {
    text-align: center;
}
.kungfu {
    color: #067EF1;
}