.spinning-wheel {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9;
}

.popup-spinner {
  position: fixed;
  top: 20%;
  left: 20%;
}

.spinning-wheel1 {
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
}

.spinner-box {
  width: fit-content !important;
  padding: 30px !important;
  background: white !important;
  border: none !important;
}

.congrats-img {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.spinner-text {
  font-family: "Open Sans";
  font-weight: 600;
  color: #eb3592;
  padding-top: 10px;
}

.spinner-content-div {
  padding: 10px;
}

.spinning-wheel-container {
  border-radius: 8px;
}

.spin-text {
  margin-top: -60px;
  margin-bottom: 40px;
  font-family: "Open Sans";
  text-decoration: underline;
  color: #eb3592;
  text-transform: uppercase;
}

.offers-btn {
  background-color: #eb3592;
  color: white !important;
  border-radius: 8px;
  padding: 5px 15px 5px;
  margin-top: 18px;
  margin-bottom: 40px;
  font-family: "Open Sans";
  width: fit-content;
  margin: 20px auto;
  border: 2px solid transparent;
}

.offers-btn:hover {
  box-shadow: -3px 3px 2px grey;
  color: #eb3592;
  border: 2px solid #eb3592;
  background-color: #fef2f3;
}

.offers-btn-link {
  color: white;
  font-size: 24px;
  height: fit-content;
  display: block;
}
.offers-btn:hover .offers-btn-link {
  color: #eb3592;
}

.wheel-of-pain {
  width: 10%;
}

.New-wheel-of-pain {
  width: 20%;
}

#wheel {
  width: 600px !important;
  display: flex !important;
  justify-content: center !important;
}

#canvas {
  width: 49vw !important;
  left: 17%;
  top: 50px;
  position: relative;
}

.lotter {
  z-index: -1 !important;
  border-top-right-radius: 8px !important ;
  border-top-left-radius: 8px !important;
}

@media only screen and (min-width: 1910px) {
  #canvas {
    width: 42vw !important;
    left: 18.5%;
    top: 50px;
    position: relative;
  }
}

@media only screen and (max-width: 1300px) {
  #canvas {
    width: 49vw !important;
    left: 17%;
    top: 50px;
    position: relative;
  }
}

@media only screen and (max-width: 1210px) {
  #canvas {
    width: 55vw !important;
    left: 14%;
    top: 50px;
    position: relative;
  }
}

@media only screen and (max-width: 900px) {
  #canvas {
    width: 72vw !important;
    left: 13%;
    top: 40px;
    position: relative;
  }
}

/* @media only screen and (min-width: 768px) {
  #canvas {
    width: 85vw !important;
    left: 13.5%;
    top: 40px;
    position: relative;
  }
} */

@media only screen and (max-width: 767px) {
  #canvas {
    width: 150vw !important;
    left: -3%;
    top: 40px;
    position: relative;
  }
  .spin-text {
    margin-top: -40px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 500px) {
  #canvas {
    width: 150vw !important;
    left: 2.8%;
    top: 40px;
    position: relative;
  }
}

@media only screen and (max-width: 415px) {
  #canvas {
    width: 150vw !important;
    left: 1.8%;
    top: 40px;
    position: relative;
  }
}

@media only screen and (max-width: 394px) {
  #canvas {
    left: -0.95%;
    position: relative;
  }
}

@media only screen and (max-width: 391px) {
  #canvas {
    left: -1.25%;
    position: relative;
  }
}

@media only screen and (max-width: 376px) {
  #canvas {
    width: 150vw !important;
    left: -3%;
    top: 40px;
    position: relative;
  }

  .spin-text {
    margin-top: -40px;
  }
}

@media only screen and (max-width: 361px) {
  #canvas {
    width: 150vw !important;
    left: -5%;
    top: 40px;
    position: relative;
  }
}
