.titl {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #067EF1;
    /* color: #7dd213; */
    text-transform: uppercase;
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  .about-part {
    background-color: #f8f7f5;
    padding: 90px 0px 90px;
    position: relative;
  }
  .about-content h {
    font-size: 80px;
    color: #ead9d9;
    text-transform: uppercase;
    margin-bottom: 20px;
    line-height: 80px;
  }
  
  .about-content h4 {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 36px;
  }
  
  .about-content p {
    margin-bottom: 25px;
    color: #ead9d9;
  }