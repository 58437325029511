.rollover-events-paragraph {
  color: black;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  font-family: optima;
  padding: 20px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .rollover-events-paragraph {
    font-size: 16px;
    line-height: 25px;
  }
}

.events-image {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-bottom: 20px;
}

.learn-more-link {
  color: #eb3593c3 !important;
  font-family: "OPEN SANS";
  font-size: 20px;
}

.learn-more-link:hover {
  color: #eb3592 !important;
}

.learn-more-link:hover svg {
  margin-left: 20px !important;
  transition: 10ms ease-in-out;

  background-color: #eb3592;
  color: white;
  border-radius: 30px;
}

.learn-more-link:hover svg {
  animation-name: shake-hover;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes shake-hover {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-8px);
  }
  20% {
    transform: translateX(6px);
  }
  30% {
    transform: translateX(-4px);
  }
  40% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-2px);
  }
  60% {
    transform: translateX(1px);
  }
  70% {
    transform: translateX(-1px);
  }
  80% {
    transform: translateX(0.5px);
  }
  90% {
    transform: translateX(-0.5px);
  }
  100% {
    transform: translateX(0);
  }
}

.Containerrr {
  background-color: #f8f7f5;
}

@media only screen and (max-width: 600px) {
  .Containerrr {
    padding-top: 15%;
  }
}

.bordder {
  border-radius: 2rem;
}

.forpadding {
  padding: 10%;
}

.Events {
  text-align: center;
  padding-top: 0;
}

#myCarousel {
  padding-top: 15px;
}

#myCarousel .carousel-item .mask {
  padding: 5rem 2rem 2rem 6rem;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
}

@media only screen and (min-width: 1910px) {
  #myCarousel .carousel-item .mask {
    padding: 0px 18rem;
  }
}

#myCarousel h4 {
  font-size: 50px;
  margin-bottom: 18px;
  color: #eb3592;
  font-family: "Open Sans";
  line-height: 100%;
  letter-spacing: 0.5px;
  font-weight: bold;
}

#myCarousel p {
  font-size: 16px;
  margin-bottom: 12px;
  color: rgb(12, 1, 1);
}

#myCarousel .carousel-item a {
  background: #ffa500fa;
  font-size: 22px;
  color: #fff;
  padding: 9px 45px;
  border-radius: 13px;
  display: inline-block;
}

#myCarousel .carousel-item a:hover {
  background: #ffa500fa;
  text-decoration: none;
}

#myCarousel .carousel-item h4 {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@media only screen and (max-width: 767px) {
  #myCarousel .carousel-item h4 {
    padding-top: 20px;
  }
  #myCarousel .carousel-item .mask {
    padding: 5rem 2rem 2rem 2rem;
  }
}

#myCarousel .carousel-item p {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  font-family: "Open Sans";
}

#myCarousel .carousel-item a {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

#myCarousel .carousel-item .mask img {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  display: block;
  height: 400px !important;
  max-width: 100%;
}

#myCarousel h4,
#myCarousel p,
#myCarousel a,
#myCarousel .carousel-item .mask img {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#myCarousel .Containerrr {
  max-width: 1200px;
}

#myCarousel .carousel-item {
  height: 100%;
  min-height: 350px;
  padding-top: 25px;
}

.carousel-control-next,
.carousel-control-prev {
  height: 40px;
  width: 40px;
  padding: 12px;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  background-color: #f47735;
}

.carousel-fade .carousel-item-left.active,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item-prev.active,
.carousel-fade .carousel-item.active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.resume {
  margin-top: 40px;
  /* background-image: url("./Media/bg.png"); */
  background-size: cover;
  margin: 0px px;
  padding: 40px 60px;
  padding-top: 0px;
  width: 100% !important;
  max-width: 100%;
}

@media only screen and (min-width: 1910px) {
  .resume {
    padding: 0px 18rem;
  }
}

.section__title {
  font-size: 32px;
  color: black;
  opacity: 75%;
  text-align: left;
  font-weight: 500;
}

.section__title2 {
  font-size: 32px;
  color: black;
  opacity: 75%;
  text-align: start;
  font-weight: 500;
}

.resume__container {
  /* grid-template-columns: repeat(2, 1fr); */
  /* column-gap: 1.875rem; */
  /* margin-top: 40px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

.timeline {
  background-color: white;
  padding: 12px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  position: relative;
  box-shadow: 0px 0px 3px #02355a;
  border-radius: 12px;
  width: 95%;
  left: 2.5%;
  height: 90%;
}

@media only screen and (max-width: 767px) {
  .resume__container {
    flex-direction: column;
  }

  .timeline {
    width: 90%;
    left: 5%;
  }

  .resume {
    /* margin: 30px 20px; */
    /* width: 90% !important; */
    max-width: 100% !important;
    padding: 30px 10px;
  }

  .container {
    margin-bottom: 0px;
  }
}

.timeline__item {
  position: relative;
  padding-left: 20px;
  padding-bottom: 0px;
  margin-top: -45px;
  padding-top: 0px !important;
  margin-bottom: 30px;
}

.timeline__item:last-child {
  padding-bottom: 0;
}

.timeline__item::before {
  content: "";
  width: 1px;
  height: 52%;
  background-color: #b99653;
  position: absolute;
  left: 0.25rem;
  top: 75px;
}

.timeline .icon-briefcase,
.timeline .icon-graduation {
  position: absolute;
  left: -0.4375rem;
  top: 0;
  font-size: var(--h2-font-size);
  color: var(--first-color);
  background-color: var(--container-color);
  padding: 0.4375rem 0;
}

.timeline__date {
  color: #b99653;
  font-size: var(--small-font-size);
  margin-left: 14px;
}

.timeline__title {
  font-size: 22px !important;
  /* opacity: 70%; */
  font-weight: 500;
  margin: 0.5rem 0;
  color: black !important;
  font-family: "Haffer";
}

@media screen and (max-width: 1024px) {
  .resume__container {
    grid-template-columns: 450px;
    justify-content: center;
    row-gap: 1.875rem;
  }

  .timeline__item::before {
    left: 2px;
  }
}

@media screen and (max-width: 576px) {
  .resume__container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 350px) {
  .timeline__item {
    padding-left: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .timeline__item::before {
    left: 0;
  }

  .timeline .icon-briefcase,
  .timeline .icon-graduation {
    left: -9px;
  }

  .timeline__text {
    text-align: justify;
  }
}

.resume-box {
  width: 100%;
}

.domain-name {
  font-weight: 600 !important;
  transition: transform 300ms ease-in-out;
}

.click-but {
  font: bold 9px consolas;
  padding: 2px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 19px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: 0.2s;
  background: #eb3592;
  font-size: 23px;
  font-family: "Open Sans";
  font-weight: 400;
}

.click-but span {
  position: relative;
  z-index: 0;
  color: white;
  font-weight: 600;
}

.click-but .liquid {
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(90deg, #eb3592 0%, #f46197 48%, #eb3592 100%);
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.7);
  z-index: -1;
  transition: 0.6s;
}

.click-but .liquid::after,
.click-but .liquid::before {
  position: absolute;
  content: "";
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  transform: translate(-25%, -75%);
}

.click-but .liquid::after {
  border-radius: 45%;
  background: #f46197;
  box-shadow: 0 0 10px 5px white, inset 0 0 5px white;
  animation: animate 5s linear infinite;
  opacity: 0.8;
}

.click-but .liquid::before {
  border-radius: 40%;
  box-shadow: 0 0 10px rgba(26, 26, 26, 0.5),
    inset 0 0 5px rgba(26, 26, 26, 0.5);
  background: fde6e7;

  animation: animate 7s linear infinite;
}

@keyframes animate {
  0% {
    transform: translate(-25%, -75%) rotate(0);
  }
  100% {
    transform: translate(-25%, -75%) rotate(360deg);
  }
}
.click-but:hover .liquid {
  top: -120px;
}

.click-but:hover {
  box-shadow: 0 0 5px #e68021, inset 0 0 5px #40d0e9;
  transition-delay: 0.2s;
}

.event-head {
  background: linear-gradient(90deg, #eb3592 0%, #f46197d1 50%, #eb3592 100%);
  padding: 4px 16px;
  border-radius: 12px;
  margin-left: 20px;
  color: white;
  font-family: "Open Sans";
  transition: transform 300ms ease-in-out;
}

.domain-name:hover {
  margin-left: 10px;
}

.domain-name:hover a .event-head {
  box-shadow: -1px 4px 13px 0px black;
}

@media only screen and (min-width: 1910px) {
  .click-but {
    bottom: 2px;
  }
}
