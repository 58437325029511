.sticky-container {
  padding: 0px;
  margin: 0px;
  position: fixed;
  right: -120px;
  top: 250px;
  width: 160px;
  z-index: 1100;
}
.sticky li {
  list-style-type: none;
  background-color: #fde6e7;
  color: #efefef;
  font-weight: bolder;
  height: 43px;
  width: 120px;
  padding: 0px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  margin: 0px 0px 1px 0px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
.sticky li:hover {
  margin-left: -80px;
}
.sticky li img {
  float: left;
  margin: 5px 4px;
  margin-right: 5px;
}
.sticky li p {
  padding-top: 12px;
  margin: 0px;
  line-height: 16px;
  font-size: 11px;
}
.sticky li p a {
  text-decoration: none;
  color: #2c3539;
}
.sticky li p a:hover {
  text-decoration: underline;
}
