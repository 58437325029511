.a-hero-part {
  display: flex;
  flex-wrap: wrap;
  gap: 0.33%;
  justify-content: space-around;
}

.a-hero-video {
  width: 16%;
}

.join-div {
  padding: 30px 40px;
  box-shadow: 0px 0px 20px 0px grey;
  margin-top: -90px;
  z-index: 2;
  position: relative;
  background: white;
  text-align: center;
}

.join-btn {
  position: absolute;
  bottom: 20px;
  left: 27%;
  margin-top: 40px;
}

.join-div:hover .join-btn {
  animation-name: shake-hover3;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes shake-hover3 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-8px);
  }
  20% {
    transform: translateY(6px);
  }
  30% {
    transform: translateY(-4px);
  }
  40% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(1px);
  }
  70% {
    transform: translateY(-1px);
  }
  80% {
    transform: translateY(0.5px);
  }
  90% {
    transform: translateY(-0.5px);
  }
  100% {
    transform: translateY(0);
  }
}

.ambassador-part {
  text-align: center;
  padding: 40px 80px;
}

.a-share-video {
  width: 23%;
}

.a-share-image {
  width: 26%;
}

.share-videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  margin-top: 60px;
}

.share-images {
  display: flex;
  justify-content: center;
  gap: 80px;
}

.post-images {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.elevate-content-part {
  border-radius: 14px;
  box-shadow: 0px 0px 3px grey;
  margin-top: -120px;
  padding: 10px;
  padding-top: 140px;
  padding-bottom: 100px;
}

.post-icon {
  color: black;
  padding: 5px;
  border: 1px solid black;
  margin-left: 5px;
  border-radius: 60px;
}

.video-div {
  overflow: hidden;
}

.post-video {
  margin-top: -120px;
  margin-bottom: -120px;
}

.join-videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

@media only screen and (min-width: 1910px) {
  .ambassador-part {
    padding: 40px 17rem;
  }
}

@media only screen and (max-width: 767px) {
  .a-hero-part {
    /* margin-top: 100px; */
    padding-top: 60px;
  }

  .a-hero-video {
    width: 48%;
  }
  .ambassador-part {
    padding: 40px 20px;
  }

  .share-images {
    flex-direction: column;
  }

  .a-share-image {
    width: 100%;
  }

  .post-images {
    flex-direction: column;
  }
  .join-div {
    width: 80%;
  }
  .join-btn {
    left: 10%;
  }
  .share-videos {
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
  .a-share-video {
    width: 100%;
  }
}
