.prepare {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;

  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  /* background-color: #fff;
  border-radius: 60px; */
  padding: 120px 0;
  padding-top: 205px;
}

.subscribe-part {
  background: linear-gradient(90deg, #0473bd 0%, #0f8cd4 48%, #0581bb 100%);
  padding: 35px 10px;
  background-image: url(./media/1.png);
}

.subscribe-part:hover .subscribe-btn {
  animation-name: shake-hover2;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes shake-hover2 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-8px);
  }
  20% {
    transform: translateY(6px);
  }
  30% {
    transform: translateY(-4px);
  }
  40% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(1px);
  }
  70% {
    transform: translateY(-1px);
  }
  80% {
    transform: translateY(0.5px);
  }
  90% {
    transform: translateY(-0.5px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-part {
    padding: 10px 5px;
  }
}
.prepare-main-title {
  font-weight: bold;
  font-family: "Open Sans";
  color: #ed3592;
}
.prepare .prepare-title {
  font-weight: 500;
  font-size: 45px;
  line-height: 68px;
  text-align: center;
  font-family: "Open Sans";
}
.prepare .prepare-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
  margin-bottom: 20px;
  font-family: "Open Sans";
  color: grey;
}
.prepare .btn-main {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #004f28;
  padding: 15px 20px;
  background: linear-gradient(90deg, #b5da7e 0%, #fcf473 48%, #c8e17a 100%);
  border-radius: 12px;
  transition: var(--transition);
  margin: 0 auto;
  animation-fill-mode: backwards;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.btn-main:hover,
.btn-main:focus,
.btn-main:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.prepare .btn-main:active {
  position: relative;
  top: 1px;
}
/* .prepare::before {
  content: "";
  position: absolute;
  bottom: 56px;
  left: 74px;
  width: 63px;
  height: 83px;
  background-image: url("./media//before.png");
}
.prepare::after {
  content: "";
  position: absolute;
  top: 74px;
  right: 80px;
  width: 63px;
  height: 83px;
  background-image: url("./media/after.png");
} */

@media screen and (max-width: 900px) {
  .prepare {
    margin: 22px auto;
    padding: 113px 27px;
    padding-top: 170px;
    width: 95%;
  }
  .prepare .prepare-title {
    font-size: 30px;
    line-height: 120%;
  }
  .prepare .prepare-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 30px;
  }
  .prepare .btn-main {
    position: relative;
    font-size: 22px;
    line-height: 120%;
    padding: 10px 26px;
    border-radius: 10px;
    z-index: 1;
  }
  .prepare .btn-main:hover {
    color: #f1dc7a;
  }
  .prepare .btn-main:active {
    position: relative;
    top: 1px;
  }
  .prepare::before {
    content: "";
    position: absolute;
    bottom: 10%;
    left: 5%;
    width: 63px;
    height: 83px;
    background-image: url("./media/before.png");
    z-index: 0;
  }
  .prepare::after {
    content: "";
    position: absolute;
    top: 5%;
    right: 5%;
    width: 63px;
    height: 83px;
    background-image: url("./media/after.png");
  }
}
