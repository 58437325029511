.a-section-one {
  background-image: url("./media/img1.png");
  height: 450px;
  width: 90%;
  border-radius: 10px;
  text-align: left;
  margin: 0 auto;
}

@media only screen and (min-width: 1910px) {
  .about-us {
    padding: 40px 14rem;
  }
}

.a-section-one h1 {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */
  color: #fefcf7;
  margin-left: 85px;
  padding-top: 137px;
}

.a-section-one p {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  width: 445px;
  color: #fefcf7;
  margin-left: 85px;
  margin-top: 20px;
}

.a-section-two {
  display: flex;
  flex-direction: row;
  width: 100%;

  margin: 0 auto;
  margin-top: 73px;
}

.a-section-two img {
  height: 542px;
  margin-left: 10.5%;
  width: 531px;
  border-radius: 8px;
}

.a-section-two h1 {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 48px;
  text-align: left;
  color: #eb3592;
}

.a-section-two p {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  text-align: left;
  mix-blend-mode: normal;
  opacity: 0.8;
}

.commitment {
  margin-left: 125px;
  align-self: center;
}

.a-section-three {
  display: flex;
  flex-direction: row;
  width: 90% !important;
  margin: 0 auto;
}

.quality {
  height: 400px;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    #fde6e7 0%,
    #fef2f3 43%,
    #fde6e7 69%,
    #eb3592 100%
  ) !important;
  margin-top: 130px;
}

.quality h1 {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */
  text-align: left;
  color: #eb3592;
  margin-left: 85px;
  margin-top: 100px;
}

.quality p {
  font-family: "Open sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: left;
  width: 579px;
  color: #000000;
  margin-left: 85px;
  mix-blend-mode: normal;
  opacity: 0.8;
}

.a-section-three img {
  position: absolute;
  height: 474px;
  width: 445px;
  border-radius: 8px;
  margin-left: -520px;
}

.holding-coffee-tablet {
  display: none;
}

.a-section-four {
  display: flex;
  flex-direction: column;
  width: 1045px;
  height: 366px;
  margin-left: 390px;
  margin-top: 150px;
}

.a-section-four h4 {
  font-family: "Fraunces 9pt";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  color: #83888f;
  text-align: left;
}

.head-quarters {
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 100px;
  margin-top: 70px;
}

.head-quarters h3 {
  font-family: "Fraunces 9pt";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 36px;
  /* identical to box height, or 112% */
  color: #333d4b;
}

.head-quarters img {
  height: 49.07225799560547px;
  width: 40.841861724853516px;
}

.head-quarters p {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  color: #333d4b;
}

.head-quarters div {
  width: 272px;
  border-radius: nullpx;
}

@media screen and (max-width: 1790px) {
  .a-section-four {
    margin-left: 165px;
  }

  .head-quarters {
    gap: 40px;
  }
}

@media screen and (max-width: 1399px) {
  .a-section-one {
    width: 1110px;
  }
}

@media screen and (max-width: 999px) {
  .a-section-one {
    background-image: url("./media/Tab.png");
    width: calc(100vw - 26px);
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
  }

  .a-section-one h1 {
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    padding-top: 118px;
    margin-left: 58px;
  }

  .a-section-one p {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    width: 398px;
    margin-left: 58px;
    margin-top: 24px;
  }

  .a-section-two {
    width: 689px;
    justify-content: space-between;
  }

  .commitment {
    margin-bottom: 55px;
  }
  .a-section-two img {
    height: 470px;
    width: 281px;
    border-radius: 8px;
  }

  .a-section-two h1 {
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;
  }

  .a-section-two p {
    width: 339px;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
  }

  .a-section-three img {
    height: 750px;
    width: 573px;
    border-radius: 8px;
    margin-left: -630px;
    margin-top: 85px;
  }

  .holding-coffee-desktop {
    display: none;
  }

  .holding-coffee-tablet {
    display: unset;
  }

  .quality {
    width: 688px;
    height: 1150px;
    border-radius: 8px;
  }

  .quality h1 {
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    margin-top: 748px;
    margin-left: unset;
    width: 100%;
  }

  .quality p {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    margin-left: unset;
    width: 537px;
    margin: 0 auto;
    opacity: 80%;
  }

  .a-section-four {
    width: 689px;
    margin-left: unset;
    margin: 0 auto;
  }

  .head-quarters {
    margin-top: 50px;
    gap: 9px;
  }

  .a-section-four h4 {
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
  }

  .head-quarters h3 {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }

  .head-quarters p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }

  .head-quarters div {
    width: 223px;
  }
}

@media screen and (max-width: 710px) {
  .a-section-two {
    flex-direction: column;
    width: 100%;
  }

  .a-section-two img {
    height: 600px;
    height: 600px;
    width: calc(100vw - 30px);
    margin-left: 0px;
  }

  .a-section-two h1 {
    text-align: center;
    margin-top: 40px;
  }

  .a-section-two p {
    text-align: center;
    width: calc(102vw - 35px);
  }

  .a-section-three {
    /* margin-top: -80px; */
    display: flex;
    flex-direction: column;
    width: calc(99vw - 30px);
  }

  .quality {
    height: 650px;
    width: calc(100vw - 40px);
  }

  .quality h1 {
    font-weight: 900;
    font-size: 28px;
    line-height: 28px;
    margin-top: 300px;
    width: calc(100vw - 100px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .quality p {
    font-size: 15px;
    line-height: 25px;
    width: calc(100vw - 100px);
  }

  .a-section-three img {
    height: 350px;
    width: 279px;
    margin-top: -750px;
    margin-left: 30px;
    margin-right: unset;
  }

  .holding-coffee-tablet {
    margin: 0 auto;
  }

  .a-section-four {
    height: unset;
    width: calc(100vw - 78px);
  }

  .a-section-four h4 {
    text-align: center;
    margin-top: 120px;
  }

  .head-quarters {
    flex-direction: column;
    text-align: center;
  }

  .head-quarters div {
    width: 100%;
    margin-bottom: 50px;
  }

  .head-quarters h3 {
    font-size: 28px;
    text-align: center;
  }

  .head-quarters img {
    text-align: center;
  }
}

@media screen and (max-width: 580px) {
  .a-section-one {
    background-image: url("./media/Mobile.png");
    height: 400px;
    border-radius: 10px;
  }

  .a-section-one h1 {
    font-size: 28px;
    line-height: 28px;
    /* identical to box height, or 100% */
    margin-left: unset;
    text-align: center;
    padding-top: 111px;
  }

  .a-section-one p {
    font-size: 15px;
    line-height: 25px;
    /* or 167% */
    text-align: center;
    margin-left: unset;
    width: 279px;
    margin: 0 auto;
  }

  .a-section-two img {
    height: 395px;
  }
}
