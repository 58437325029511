.Comp {
  background-color: #f8f7f5;
}

.menu-options {
  margin-top: 60px;
}
.single {
  height: 40px;
  width: auto;
  border-radius: 20px;
  border: 1px solid #ff652a;
  color: #ff652a;
  padding: 5px 20px;
  text-align: center;
  margin: 6px 10px;
  text-transform: uppercase;
  cursor: pointer;
  color: rgb(186, 147, 99);
}
.tablink {
  color: black !important;
  font-weight: bold;
  font-family: georgia;
}
.tablink.active {
  background-color: #ff652a !important;
  border-radius: 20px !important;
}

.single:hover {
  background-color: #ff652a;
  color: #fff;
}
.inOptions {
  margin-top: 30px;
  background-color: #242424;
}
.inOptionsHead {
  color: #ff652f;
  margin: 5px auto;
  text-transform: capitalize;
  font-size: 240%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Style App.js wrapper */
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.backbox {
  background-color: transparent;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .backbox {
    padding-top: 12%;
  }
}
.padding {
  color: rgb(12, 2, 2);
  text-align: center;
}
.headingit {
  text-align: center;
  font-size: 2.3rem;
  font-family: "OPEN SANS";
  font-weight: bold;
}
@media only screen and (max-width: 900px) {
  .headingit {
    font-size: 1.8rem;
  }
}
.ordert {
  padding-bottom: 0.5%;
  padding: center;
  text-align: center;
}
.imgggg {
  padding-top: 0.5%;
  padding-right: 2%;
  width: 12%;
  display: initial;
}
@media only screen and (max-width: 600px) {
  .imgggg {
    width: 30%;
    padding-right: 4%;
  }
}

.frezee {
  background-color: #f8f7f5;

  padding-top: 5%;
}

@media only screen and (min-width: 1910px) {
  .frezee {
    padding-top: 3%;
  }
}

@media only screen and (max-width: 600px) {
  .frezee {
    padding-top: 12%;
  }
}
.scrollerr {
}
@media only screen and (max-width: 600px) {
  .scrollerr {
  }
}

.Tabhead {
  text-align: center;
  height: 470px;
  position: -webkit-sticky;
  position: sticky !important;
  top: 100px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .Tabhead {
    padding-top: 2%;
    padding-bottom: 5%;
    top: 0;
    height: 100%;
    position: relative !important;
  }
}
.flex-column {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .flex-column {
    position: relative;
    width: 100%;
  }
}
