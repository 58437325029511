.ingredients-component {
  background-image: url(./Media/nutrition-bg.png);
  justify-content: center;
  background-position: 50%;
  background-size: cover;
  bottom: 10rem;
  position: relative;
  display: flex;
  top: 2rem;
  flex-direction: column;
  padding-top: 30px;
  height: 750px;
  width: 100%;
  background-repeat: no-repeat;
  text-align: center;
}

.ingredients-head1 {
  font-family: "Open Sans";
  font-size: 120px;
  color: #eb3592;
  font-weight: bolder;
}

.ingredients-head2 {
  font-family: "Open Sans";
  font-size: 55px;
  color: #eb3592;
  text-transform: uppercase;
  font-weight: bolder;
}

.store-content2 {
  font-size: 18px;
  text-align: center;
  align-items: center;
  font-family: "Open Sans";
  margin-top: 0px;
  margin-bottom: 18px;
  color: #000000fa;
  line-height: 31px;
}

.for-real {
  font-size: 50px;
  font-family: cursive;
  font-weight: bolder;
}

.ingredients-component-btn {
  text-align: center;
  border: 1px solid #eb3592;
  color: #eb3592;
  margin: 0px auto;
  padding: 5px 30px;
  text-transform: uppercase;
  margin-top: 10px;
  font-family: "Open Sans";
  font-weight: 600;
}

.ingredients-component-btn:hover {
  color: orange;
}

.ingredients-part {
  padding: 120px 80px;
  text-align: center;
}

.ingredients-hero {
  border-bottom: 2px solid #eb3592;
  padding: 40px;
  padding-top: 0px;
}

.ingredients-items-section {
  display: flex;
  flex-wrap: wrap;
}

.ingredient-item-name {
  font-family: "Open Sans";
  font-size: 16px;
}

.ingredient-item {
  width: 18%;
  padding: 45px;
  display: flex;
  flex-direction: column;
  margin: 0px 1%;
}

.ingredient-image {
  margin-bottom: 20px;
  padding: 15px 0px;
}

.ingredient-item:hover .ingredient-image {
  background-color: #00000021 !important;
  cursor: pointer;
  border-radius: 120px;
}

.slider-inner-content {
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 24px;
}

@media only screen and (min-width: 1910px) {
  .ingredients-part {
    padding: 120px 17rem;
    padding-bottom: 40px;
  }
}

.close-btn {
  background: #eb3592 !important;
  padding: 5px 10px;
  border-radius: 8px;
  font-family: "Open Sans";
  color: white;
}

/* -------------********************************---------------- 
     MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
            Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.widget-heading {
  font-size: 25px;
  font-weight: bold;
  width: 100%;

  font-family: "Open Sans";
}

.cart-vacio-container {
  width: 25rem;
}

.cartVacio {
  text-align: center;
  margin-top: 5px;
  font-size: 15px;
}

.productsContainer {
  max-height: 200px;
  overflow-y: scroll;
  margin: 15px 10px;
  border: 1px solid #fbcb04;
  border-radius: 12px;
}

.cartItem {
  height: 90px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.btn-icon-cart {
  background-color: white;
  border: none;
  cursor: pointer;
  width: 30px;
  color: red;
}

.cartImg {
  height: 90px;
  width: 75px;
  border-radius: 5px;
}

.cart-modal {
  text-align: center;
}

.footer-modal-cart {
  display: flex;
  justify-content: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.solid {
  border: 2px;
  border-radius: 5px;
  background-color: #fbcb04;
  color: white;
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 15px;
  border-bottom: 2px solid black;
}

.solid:hover {
  background-color: #ffe053;
  color: black;
}

.checkout-btn {
  padding: 9px 25px;
}

.cart-slider {
  width: 40%;
}

.SlideModal {
  width: 30% !important;
  height: 100vh !important;
  background-color: #fef2f3 !important;
}

.SlideModal__title {
  font-family: "Haffer" !important;
  font-size: 22px;
  color: #fbcb04 !important;
}

.SlideModal__header {
  display: initial !important;
  padding: 20px;
}

.h-overflowAuto {
  overflow: auto !important;
  display: flex;
  justify-content: center;
  place-content: center;
  place-items: start;
}

.slider-inner-heading {
  font-family: "Open Sans";
  font-size: 24px;
  color: #eb3592;
  text-transform: uppercase;
  font-weight: bolder;
}

.cart-paragraph {
  font-size: 15px;
  text-align: center;

  font-family: "Open Sans", sans-serif;
}

.SlideWrapper {
  position: relative !important;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .SlideModal {
    width: 100% !important;
  }

  .widget-heading {
    margin-left: 0px;
  }
}
