.Animate-Cards-Part {
  padding: 15px;
  padding-bottom: 0px;
}

.item {
  position: relative;
  display: block;
  overflow: hidden;
}
.item::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.25;
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s;
}

.item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
}
.item .item-content {
  position: absolute;
  top: 10rem;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
}
.item .item-content .item-title {
  position: relative;
  text-align: center;
}
.item .item-content h3 {
  position: relative;
  display: inline-block;
  color: #fff;
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0 0 0.75rem;
  font-family: "Graphik Dan Web", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  transform: translateY(50%);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.item .item-content h3::before,
.item .item-content h3::after {
  position: absolute;
  content: "";
  bottom: -10px;
  height: 1px;
  opacity: 0;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.item .item-content h3::before {
  left: 100%;
  right: 50%;
}
.item .item-content h3::after {
  left: 50%;
  right: 100%;
}
.item .item-content p {
  position: relative;
  color: #fff;
  transform: translateY(-50%);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 24px;
  margin-top: 15px;
  opacity: 0;
}
.item:hover img {
  transform: scale(1.075);
  transition: 1.2s transform cubic-bezier(0.165, 0.84, 0.44, 1);
}
.item:hover .item-content .item-title h3 {
  transform: translateY(0);
}
.item:hover .item-content .item-title h3::before {
  left: 0;
  right: 50%;
  opacity: 1;
}
.item:hover .item-content .item-title h3::after {
  left: 50%;
  right: 0;
  opacity: 1;
}
.item:hover .item-content .item-title p {
  transform: translateY(0);
  opacity: 1;
}
