.columnn {
  float: left;
  width: 50%;
  padding: 5px;
}
@media only screen and (max-width: 700px) {
  .columnn {
    width: 100%;
  }
}

.roww {
  content: "";
  clear: both;
  display: table;
  padding: 2%;
  padding-bottom: 10px;
}
.roww::after {
  content: "";
  clear: both;
  display: table;
}

.wapper {
  height: 100%;
  font-family: Helvetica, sans-serif;
  line-height: 1.5;
}

.wglk {
  padding-top: 3.3%;
}

@media only screen and (max-width: 600px) {
  .wglk {
    padding-top: 14%;
  }
}
.fixed {
  display: table;
}

.centeredd {
  background-color: none;
  position: absolute;
  padding: 10px 70px;
  color: white;
  /*z-index:20;*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;

  text-align: center;
}
@media only screen and (max-width: 700px) {
  .centeredd {
    padding-bottom: 10%;
    width: 80%;
  }
}

.centereddd {
  background-color: none;
  position: absolute;

  padding: 10px 70px;
  color: lavender;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
}
@media only screen and (max-width: 700px) {
  .centereddd {
    padding-bottom: 10%;
    width: 80%;
  }
}
.centeredddd {
  background-color: none;
  position: absolute;
  padding: 10px 70px;
  color: white;
  top: 250%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
}
@media only screen and (max-width: 700px) {
  .centeredddd {
    padding-bottom: 20%;
    width: 80%;
  }
}
.image-1 {
  background-image: url("./images/img3.jpg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;

  background-attachment: fixed;
  filter: blur(7px);
  -webkit-filter: blur(7px);
  filter: brightness(40%);
  opacity: 1;
}
.image-2 {
  background-image: url("./images/img2.jpeg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;

  background-attachment: fixed;
  filter: blur(7px);
  -webkit-filter: blur(7px);
  filter: brightness(40%);
  opacity: 1;
}
.image-3 {
  background-image: url("./images/img1.jpeg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;

  background-attachment: fixed;
  filter: blur(7px);
  -webkit-filter: blur(7px);
  filter: brightness(40%);
  opacity: 1;
}
.pp {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 5px;
}
.hh {
  font-size: 50px;
  font-weight: bold;
  color: palevioletred;
  text-transform: uppercase;
}
@media only screen and (max-width: 900px) {
  .hh {
    font-size: 30px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 900px) {
  .pp {
    font-size: 20px;
    font-weight: bold;
  }
}
.kungff-2 {
  height: 60vh;
  width: 100%;
  object-fit: cover;

  top: 0;
  left: 0;
  z-index: -1;
}
@media only screen and (max-width: 768px) {
  .kungff-2 {
    height: 100vh;
    width: 100%;
  }
}

.lotter {
  padding-top: 30%;
}
.vidplay-2 {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .vidplay-2 {
    display: none;
  }
}
.kungff {
  height: 60vh;
  width: 100%;
  object-fit: cover;

  top: 0;
  left: 0;
  z-index: -1;
}
@media only screen and (max-width: 768px) {
  .kungff {
    height: 100vh;
    width: 100%;
  }
}
.bgnam-2 {
  position: absolute;
  padding-top: 15%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  height: 100vh;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: white;
}
@media only screen and (max-width: 768px) {
  .bgnam-2 {
    padding-top: 50%;
  }
}

.bgnam {
  position: absolute;
  padding-top: 15%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  height: 100vh;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: white;
}

.new-offers {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
  justify-content: center;
  padding: 10px;
  margin-top: 80px;
}

.offer-div {
  width: 48%;
  margin-bottom: 20px;
}

.offer-part {
  overflow: hidden;
}

@media only screen and (min-width: 1910px) {
  .roww {
    padding: 40px 18rem;
    padding-bottom: 10px;
  }

  .new-offers {
    padding: 10px 17rem;
  }
}

@media only screen and (max-width: 768px) {
  .bgnam {
    padding-top: 50%;
  }

  .offer-div {
    width: 100%;
  }
}
