.message-icon1 {
  animation: scaleAnimation 2s infinite;
  transform: scale(1);
  color: green;
}

@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

/* ----- MEDIA QUERIES (MEDIUM DEVICES) ----- */

@media screen and (max-width: 1024px) {
  .modal-message-send {
    bottom: -50px;
  }
}
