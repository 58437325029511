.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: red;
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition) !important;
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea,
select {
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
  width: 350px;
  padding: 0.5rem;
  border-radius: 0.5rem !important;
  background: transparent;
  border: 2px solid black;
  color: black;
  font-weight: bold;
}
textarea {
  resize: vertical;
  min-height: 150px;
  max-height: 295px;
  width: 730px;
  height: 100%;
}

.dob-label {
  position: relative;
}

.dob {
  position: absolute;
  top: 16px;
  left: 10px;
  background: #fef2f3;
  color: #000000a1;
  font-size: 15px;
  padding-right: 10px;
}

@media screen and (max-width: 1024px) {
  textarea {
    width: 730px;
  }
}
::placeholder {
  font-size: 0.9rem;
}

/* ----- MEDIA QUERIES (MEDIUM DEVICES) ----- */

@media screen and (max-width: 1024px) {
  .contact__container {
    grid-template-columns: 1fr;
    gap: 4rem;
  }
}
.error-text {
  color: Red;
}
