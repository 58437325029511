.Testimoni {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
}

@media only screen and (min-width: 1910px) {
  .Testimoni {
    padding: 40px 16rem;
  }
}

.titletesti {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: #ed3592;
  font-size: 22px;
}

.nametesti p {
  font-size: 18px;
  font-weight: 700;
  color: #ffb23f;

  margin-bottom: 0px;
}

.nametesti h1 {
  font-size: 36px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Open Sans";
}

.desctesti {
  width: 656px;
}

.desctesti h3 {
  font-weight: 500;
  font-size: 18px;
  color: #afadb5;
}

.cardtesti {
  display: flex;
  gap: 20px;
}

@media screen and (max-device-width: 639px) {
  .nametesti p {
    font-size: 14px;
  }

  .nametesti h1 {
    font-size: 24px;
  }

  .desctesti {
    width: 300px;
  }

  .desctesti h3 {
    font-size: 14px;
  }
}
