.TabsList {
  /* padding-top: 100px; */
  position: -webkit- sticky;
  position: sticky;
  top: 70px;
  z-index: 99;
  background-color: aliceblue;
  box-shadow: 0px 15px 10px -15px rgba(17, 17, 17, 0.577);
  font-family: "Open Sans";
}

@media only screen and (min-width: 1910px) {
  .TabsList {
    padding: 0px 18rem;
    top: 55px;
  }
}

.rts___nav___btn___container {
}
@media only screen and (max-width: 600px) {
  .rts___nav___btn___container {
    width: 81px;
  }

  .TabsList {
    z-index: 1;
  }
}

.rts___tab___selected .menu-icon {
  color: black;
}

.menu-icon {
  font-size: 38px;
  color: #eb3592;
  text-align: -webkit-center;
}

/* from animate.css */
.animate__animated {
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;

  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  margin-top: 55px;
}

.rts___nav___btn {
  position: relative;
  /* border: none; */
  /* width: 100%; */
  /* position: absolute; */
  width: 26px;
  height: 26px;
  /* align-items: center; */
  /* justify-content: center; */
  top: 50%;
  transform: translate(0, -50%);
}
.rts___tab___selected {
  background: linear-gradient(
    90deg,
    #eb3592 0%,
    #f46197 48%,
    #eb3592 100%
  ) !important;
  color: #000000;
}
.rts___btn {
  border-radius: 14px;
  border: none;
}

.menu-name {
  margin-top: 5px;
}

.rts___btn:hover {
  background-color: #eb3592;
  color: black !important;
}

.rts___nav___btn:hover {
  background: #eb3592;
}

.rts___btn:hover .menu-icon svg {
  color: black;
}
