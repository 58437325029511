.boxtesti {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;

  /* width: 550px; */
  padding: 30px;
  margin-right: 15px;
  margin-bottom: 50px;
}
.texttesti{
  height: 170px;
}
.boxtesti p {
  font-size: 18px;
}

.avarate {
  display: flex;
  justify-content: space-between;
}

.namerate {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.namerate h4 {
  font-size: 20px;
  margin: 0px;
  margin-left: 2px;
}

.starrating {
  display: flex;
  align-items: center;
}

.starrating p {
  margin-left: 10px;
  font-weight: 700;
  padding-top: 28%;
}
