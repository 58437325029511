.slide-part {
  position: relative;
  width: 100%;
  height: 100vh;
}
.slide-part-image {
  width: 100%;
  height: 100vh;
}

.show {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fade 1.5s ease-in-out;
}

.show > div {
  width: 100%;
  height: 100%;
}

/* @-webkit-keyframes trans {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

@keyframes trans {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
} */

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.not-show {
  display: none;
}

.prev-but {
  position: absolute;
  top: 50%;
  font-size: 50px;
  left: 5px;
  transform: translateY(-50%);
}

.next-but {
  position: absolute;
  top: 50%;
  font-size: 50px;
  right: 15px;
  transform: translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .prev-but {
    font-size: 30px;
  }
  .next-but {
    font-size: 30px;
  }
}

.page {
  text-align: center;
}
.dot {
  cursor: pointer;
  height: 5px;
  width: 20px;
  margin: 0 2px;
  background-color: #ddd;
  display: inline-flex;
  transition: background-color 5s ease;
}
