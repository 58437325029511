.navigation {
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 99;
  padding: 0.5rem 0.5rem;
  background-color: #fde6e7;
  color: #009e4f;
  font-family: "Lora", serif;
  box-shadow: 0 14px 28px rgb(0 0 0 / 0%), 0 10px 10px rgb(0 0 0 / 7%);
}
.navigation-smallpart {
  height: 55px;
}

@media screen and (min-width: 1910px) {
  .navigation {
    width: 100%;
    padding: 0px 15rem 0rem 13rem;
  }
}

.brandd {
  position: absolute;

  padding-left: 73%;
}

.Sampp {
  color: #eee;
  font-family: "Lora", serif;
}

.brand-name {
  text-decoration: none;
  color: #009e4f;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .brand-name {
    margin-left: 0rem;
  }
}

.navigation-menu {
  margin-left: auto;
  font-size: 1.4rem;
  font-family: "Lora", serif;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  align-items: center;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  font-family: "Open Sans";
  color: #29392d;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 1.5px !important;
}

.navigation-menu li a:hover {
  color: #eb3592;
  text-transform: scale(1.5) !important;
  margin-top: -5px;
  border-bottom: 4px solid #eb3592;
  transition: 0.2s ease-out;
  text-shadow: 1px 1px 1px black;
}

.hamburger {
  border: 0;
  height: 50px;
  width: 47px;
  padding: 0rem;
  border-radius: 50%;
  background-color: black;
  color: #eee;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: none;
}

.samppp {
  margin-bottom: 0%;
  color: #00aaaa;
  margin-top: 0.5%;
}

.new-blink {
  padding-left: 15px;
  position: relative;
}

.new-blink::after {
  content: "";
  width: 140px;
  background: url(./blinkernew.gif);
  height: 71px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -64%;
  right: 3rem;
  transform: rotate(334deg);
}

.new-blink {
  padding: 5px 10px;
  border-radius: 8px;
  margin-left: 25px;
}

.new-blink:hover {
  border-bottom: none !important;
}

@media screen and (min-width: 1910px) {
  .new-blink::after {
    right: 3rem;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#samppp {
  margin-bottom: 0%;
  color: aliceblue;
}

.hamburger:hover {
  background-color: #010209;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
  .new-blink {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
    font-family: "Lora", serif;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    position: absolute;
    top: 48px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 470px;
    border-bottom: 2px solid #eb3592;
    background: #fef2f3;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: #eb3592;

    width: 100%;
    padding: 1.5rem 0;
    margin: auto;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    /* previous styles */
    dipslay: none;
  }
  .samppp {
    margin-top: 5.5%;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu.expanded ul {
    display: block;
  }
}

.samppppppp {
  width: fit-content !important;
  padding: 2px;
  margin: 0px 30px;
  outline: none;
  border: none;
  font-size: 21px;
  font-family: cursive;
  color: #004f28;
  font-weight: 600;
  background: #2be7d8;
  cursor: pointer;
  transition: 70ms;
}

.samppppppp:hover {
  color: rgb(0, 0, 0) !important;
}

@media only screen and (max-width: 700px) {
  .samppppppp {
    background-color: rgba(60, 198, 26, 0);
    font-size: 20px;
    padding: 5px 20px !important;
    margin-top: 4px;
  }
}

.samppppppp button:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 0px 0px #f09e23fa;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: none;
}

.dropbtn {
  background: none;

  font-size: 20px;
  border: none;
  margin-bottom: 0%;
  color: aliceblue;
  font-family: "Lora", serif;
}

@media only screen and (max-width: 700px) {
  .dropbtn {
    font-size: 20px;
    color: rgb(0, 4, 8);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 1rem;
  background-color: #f1f1f1;
  min-width: 160px;

  z-index: 1;
}

@media only screen and (max-width: 700px) {
  .dropdown-content {
    display: none;
    position: relative;
    border-radius: 1rem;
    background-color: none;

    transition: 70ms;
    width: 200px !important;
  }
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
}
