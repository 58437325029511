.subscribe-parttt {
  background-color: #fef2f3;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .subscribe-parttt {
    border-radius: 0px;
    padding-top: 5%;
    padding-bottom: 8%;
    margin-bottom: 0;
    margin-top: 0;
  }

  .title {
    margin-bottom: -10px;
    margin-top: 25px;
  }
  .container {
    max-width: 100% !important;
  }
}

.sub-image {
  height: 100% !important;
  width: 100% !important;
  margin: 4% 4% 4% 1%;
  border-radius: 20px;
}
/*
img {
    height: 100%;
    width: 100%;
}
*/
