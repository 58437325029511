.react-tabs {
  border-top: #eb3592 solid 2px;
  display: flex;

  width: 100%;
  height: 100%;

  color: white;
  background: white;
}

.store-heading {
  font-size: 46px;
  text-align: center;
  font-family: "Open Sans";
  font-weight: bold;
  margin-top: 60px;
  color: #eb3592;
}

.store-content {
  font-size: 16px;
  text-align: center;
  align-items: center;
  font-family: "Open Sans";
  margin-top: 12px;
  margin-bottom: 18px;
  color: #000000fa;
  line-height: 30px;
  font-weight: 400;
  padding: 10px 60px;
}

.event-heading {
  font-size: 34px;
  color: #ffa500fa;
  text-align: left;
  font-family: optima;
  font-weight: bold;
  padding: 20px;
}

.event-content {
  color: black;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  font-family: optima;
  padding: 20px;
  text-align: left;
}

.enent-image {
  height: 200px !important;
  width: 100px !important;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  padding: 0;
  color: white;
  /* background: #3c3e43; */
  width: 25%;
  height: 800px !important;
  overflow: scroll;
}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  font-family: georgia;

  font-size: 23px;
  padding: 12px 6px;
  margin-right: 10%;
  cursor: pointer;
  color: #000000;
}
.react-tabs__tab:hover {
  color: #ffa500fa;
}

.react-tabs__tab--selected {
  border-left: 8px solid #ffa500fa;
  color: #ffa500fa;
  font-size: 23px;
}

.react-tabs__tab-panel {
  display: none;
  width: 70%;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
  padding-top: 21px;

  border: 1px solid white;
  border-bottom: 0px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
  padding-top: 21px;
  font-size: 23px;
  border-top: 1px solid white !important;
}

.react-tabs__tab--selected:focus {
  border: 0px solid black;
  outline: 0px solid black;
  border-left: 5px solid #ffa500fa;
}

.contentfulll {
  flex-wrap: nowrap !important;
  width: 100%;
}
.panel-content {
  text-align: center;
}
.image-123 {
  overflow: hidden;
}

.image-12 {
  transform: scale(1.25);
  transform: scaleX(1.25);
}

@media only screen and (min-width: 1910px) {
  .outdoor-part {
    padding: 40px 18rem;
  }
  .store-heading {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .react-tabs {
    flex-direction: column;
  }
  .react-tabs__tab-list {
    width: 90%;
    text-align: center;
    font-size: 32px !important;
    height: 220px !important;
    margin: 20px;
  }
  .react-tabs__tab-panel {
    width: 100%;
  }
  .store-content {
    font-size: 20px;
    text-align: center;
    align-items: center;
    font-family: optima;
    margin-top: 12px;
    margin-bottom: 18px;
    color: #000000fa;
    width: 90%;
    margin-left: 5%;
  }
}
