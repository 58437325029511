@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.content {
  /* background: #f8f7f5; */
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .content {
    flex-direction: column;
  }
}
.light-img {
  width: 430px;
  padding: 25px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .light-img {
    width: 100%;
  }
}
.titleee {
  text-align: center;
  background: #f8f7f5;
  font-weight: bold;
  color: #eb3592;
  font-family: "Open Sans";
}
.lightbox-cont {
  background: #f8f7f5;
}

@media only screen and (min-width: 1910px) {
  .lightbox-cont {
    padding: 0px 7rem;
  }
}
