@import url("https://fonts.googleapis.com/css2?family=Concert+One&family=Lato:wght@100;300;400;500;600;700;800;900&family=Open+Sans:wght@100;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akronim&family=Bungee+Shade&family=Cabin+Sketch:wght@400;700&family=Concert+One&family=Ewert&family=Lato:wght@400;700&family=Open+Sans:wght@700&display=swap");

@font-face {
  font-family: "Glitch";
  src: url("https://example.com/Glitch.woff2") format("woff2"),
    url("https://example.com/Glitch.ttf") format("truetype");
}

@keyframes glitch {
  0% {
    transform: scale(1, 1);
    text-shadow: 0 0 0 rgba(255, 255, 255, 1);
  }
  20% {
    transform: scale(0.98, 1.01);
    text-shadow: 0.1em -0.05em 0 rgba(255, 255, 255, 0.8),
      -0.1em 0.05em 0 rgba(255, 255, 255, 0.8);
  }
  40% {
    transform: scale(1, 1);
    text-shadow: 0 0 0 rgba(255, 255, 255, 1);
  }
  100% {
    transform: scale(1, 1);
    text-shadow: 0 0 0 rgba(255, 255, 255, 1);
  }
}

body {
  cursor: default;
  user-select: none;
}

.wierd {
  font-family: "Glitch";
  /* animation: glitch 1s linear infinite; */
  text-align: center;
  line-height: 1;
  user-select: none;
}

.bgclr {
  background-color: #fff;
  padding-top: 0%;
}

@media only screen and (max-width: 900px) {
  .bgclr {
    background-color: #fff;
    padding-top: 0%;
  }
}
input {
  overflow: visible;
  padding: 10px !important;
}
/* *,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
/* Start https://proxy.everskies.com/a/https://proxy.everskies.com/a/https://www.cursors-4u.com */
/* * {
  cursor: url(https://cur.cursors-4u.net/others/oth-8/oth702.cur), auto  !important;
  padding: 5%
} */

.popup {
  background-color: #fef2f3;
  width: 502px;
  position: fixed;
  transform: translate(-50%, -48%);
  left: 50%;
  top: 48%;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  display: none;
  text-align: center;
  z-index: 999;
  box-shadow: 0px 0px 5px;
}
@media only screen and (max-width: 600px) {
  .popup {
    width: 90%;
    position: fixed;
    padding-bottom: 20px;
  }
}
.popup button {
  display: block;
  margin: 0 0 20px auto;
  background-color: transparent;
  font-size: 30px;
  color: #c5c5c5;
  border: none;
  outline: none;
  cursor: pointer;
}
.popup p {
  font-size: 14px;
  margin: 20px 0;
  font-family: "Open Sans";
}

.blink-image {
  -moz-animation: blink normal 1s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 1s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 1s infinite ease-in-out; /* IE */
  animation: blink normal 1s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#close {
  color: black;
  position: absolute;
  right: 14px;
  z-index: 9;
}

.t-padding {
  padding: 0px 60px;
}

@media only screen and (min-width: 1910px) {
  .t-padding {
    padding: 0px 14rem;
  }
}

@media only screen and (max-width: 767px) {
  .t-padding {
    padding: 0px 20px;
  }
}

.single-item {
  padding-top: 120px;
  padding-bottom: 40px;
}

/* ------- CSS From Sales 21 --------- */
/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */
.website-template-main {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
}
.website-builder-part {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 60px;
  padding: 2rem 9rem 11rem 7rem;
  display: flex;
  z-index: 0;
  position: relative;
}

.website-builder-left-part {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.website-builder-right-part {
  width: 50%;
}
.website-builder-img1 {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  width: 21%;
  border-radius: 12px;
  right: 6rem;
  z-index: 3;
}

.website-builder-img2 {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  width: 40rem;
  border-radius: 20px;
  bottom: -3rem;
  z-index: 2;
}

.website-builder-title {
  padding-left: 60px;
  padding-top: 30px;
  color: white;
}

.show-more-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  margin-top: -58px;
}

.website-skeleton {
  padding-bottom: 60px;
  padding: 2rem 9rem 5rem 7rem;
  padding-top: 8rem;
}

.website-skeleton1 {
}

.website-skeleton2 {
  padding: 2rem 3rem 1rem 3rem;
  display: flex;
  flex-wrap: wrap;
}
.website-hero-img {
  bottom: 16%;
  right: 0%;
}

.website-template-div {
  display: flex;
}

.website-template-div1 {
  width: 50%;
  display: flex;
}

.template-ul {
  list-style: disc;
  list-style: inside;
  text-decoration-color: #0000ff73;
  margin: 10px 0px;
}

.template-ul::marker {
  color: orange;
}

.website-template-image-div {
  width: 40%;
  padding: 20px;
  padding-top: 0px;
  padding-left: 0px;
}

.website-template-image-div1 {
  width: 30%;
  padding: 20px;
  padding-top: 0px;
  padding-left: 0px;
}

.why-choose-icon {
  font-size: 30px;
  margin-top: 20px;
  margin-right: 40px;
  color: #11c7e6;
}

.support-part {
  padding-top: 40px;
}

.specific-image {
  padding: 40px;
  padding-left: 0px;
  padding-top: 0px;
}

.specific-image1 {
  width: 25px;
  height: 25px;
}

.order-btn {
  padding: 10px;
  border-radius: 8px;
  transition: transform 300ms ease-in-out;
}

.order-btn:hover {
  color: white;
  box-shadow: -2px 3px 4px grey;
  transform: scale(1.05);
}

.specific-type-div {
  padding: 5px 15px;
  border: 1px #80808069 solid;
  border-radius: 30px;
}

.back-btn {
  background: #eb3592;
  padding: 5px 10px;
  border-radius: 8px;
  color: white;
  margin: 10px 6px;
  margin-top: 21px !important;
  position: absolute;
  font-family: "Open Sans";
  transition: transform 300ms ease-in-out;
}

.back-btn:hover {
  color: white;
  box-shadow: -2px 3px 4px grey;
  transform: scale(1.05);
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .website-builder-title {
    padding: 30px 1rem 0px 1rem;
  }
  .website-builder-title-sub {
    padding: 18px 1rem;
  }
  .website-builder-part {
    padding: 40px 24rem 100px;
  }

  .website-skeleton {
    padding: 40px 24rem;
    padding-top: 120px;
  }

  .website-skeleton2 {
    padding: 40px 14rem;
  }

  .website-builder-img1 {
    width: 20%;
    right: 23rem;
  }

  .website-builder-img2 {
    width: 29%;
    bottom: -2rem;
  }
  .website-hero-img {
    bottom: 22%;
    right: 0%;
  }
}

/* -------------********************************---------------- 
                  SCREEN - Max - 1236px 
--------------- ********************************---------------- */

@media only screen and (max-width: 1236px) {
  .website-builder-container {
    width: 30% !important;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .website-builder-container {
    width: 10% !important;
    left: 6%;
  }

  .blog-card-image1 {
    height: 190px !important;
  }

  .website-builder-title {
    padding-left: 17px;
  }
  .website-skeleton {
    padding: 120px 20px;
    flex-direction: column;
  }

  .website-skeleton2 {
    padding: 40px 20px;
    flex-direction: column;
  }
  .website-builder-left-part {
    width: 100%;
  }

  .website-builder-right-part {
    width: 100%;
  }
  .website-template-div {
    flex-direction: column;
  }
  .website-template-div1 {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .website-template-image-div {
    width: 100%;
    padding: 0px;
  }
  .website-template-image-div1 {
    width: 60%;
    margin: auto;
  }
  .react-player-new {
    width: 100%;
  }
  .specific-types {
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
  }

  .specific-type-div {
    width: fit-content;
    padding: 5px;
  }

  .single-item {
    padding-bottom: 90px;
  }
  .specific-image {
    padding: 0px;
  }
}

.Website-builder-head {
  font-size: 1.15rem;
  line-height: 160%;
  margin-top: 1rem;
  color: white;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.main-wheel {
  position: fixed;
  width: -moz-fit-content;
  width: fit-content;
  left: -350px;
  top: 26%;
  z-index: 99;
  transform: scale(1.5);
  animation: spin 2s linear infinite; /* Adjust the duration as needed */
  transition: transform 300ms ease-in-out;
}

.main-wheel:hover {
  left: -250px;
  transform: scale(1.2) rotate(360deg);
  cursor: pointer;
}
