@import url("https://fonts.googleapis.com/css?family=Alkatra&display=swap");
@import url("https://fonts.googleapis.com/css?family=Merienda&display=swap");
.ordere {
  padding: center;
  text-align: center;
  font-family: "Lora", serif;
  justify-content: space-between;
}

@media only screen and (min-width: 1910px) {
  .productss {
    padding: 0px 17rem;
  }
}

@media only screen and (max-width: 900px) {
  .productss {
    padding-left: 1%;
  }
}

.imgtut {
  height: 300px;
  width: 300px;
  box-shadow: -4px -4px #fdc500;
  border-radius: 1rem;
  margin-top: 2rem;
  z-index: 1;
}

.fish {
  position: relative;
  width: 3%;
  right: 30px;
  top: -110px;
  z-index: 2;
}

@media only screen and (max-width: 900px) {
  .fish {
    width: 7%;
    top: -105px;
  }
}

@media only screen and (max-width: 900px) {
  .imgtut {
    height: 300px;
    width: 300px;
  }
}

.infomes {
  padding: 1rem;
  float: right;
  margin-top: 2rem;
  padding-right: 35%;
  right: 60%;
}

@media only screen and (max-width: 900px) {
  .infomes {
    flex-wrap: nowrap;
    padding-right: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .heade {
    margin-bottom: 10%;
    font-size: 1rem;
  }
}

.heade {
  font-weight: 200;
  font-size: 1rem;
  float: right;
  position: absolute;
  float: right;
  font-weight: bold;
}

.pricing {
  font-size: 1rem;
  margin-top: 2rem;
  font-family: cursive;
  font-weight: bold;
}

.productbut {
  border: black;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 2rem;
  width: 100%;
  background-color: rgba(33, 50, 206, 0.815);
}

.modal {
  background-color: white;
  width: 100%;
  height: 100%;
}

.imgu {
  width: 100%;
  height: 100%;
}

@import url(https://fonts.googleapis.com/css?family=Satisfy);
@import url(https://fonts.googleapis.com/css?family=Acme);

.itemboxwrap {
  padding-bottom: 3%;
}

.box-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  /* --------------- AV -----------------*/
  border-style: solid;
  border-width: 1px;
  border-color: #eb3592;
  border-radius: 15px;
  width: 100%;
  height: 200px;
  background-color: #fde6e7;

  /* ------------------ AV ---------------*/
  overflow: hidden;

  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.48);
  padding: 0;
  position: relative;
}
.box-wrapper-popup {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  border-radius: 15px;
  width: 100%;
  height: 500px;
  background-color: #e2ab861f;

  /* ------------------ AV ---------------*/
  overflow: hidden;
  padding: 0;
  position: relative;
}

.box-wrapper-popup img {
  /*display: flex;*/

  height: 500px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.titlerr-popup {
  font-size: 3em;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  font-family: serif;
  color: #eb3592;
  padding-top: 9px;
  padding-left: 10px;
  font-family: "Open Sans";
}
.desc-popup {
  font-family: "Merienda";
  font-size: 20px;
  width: 85%;
  padding-left: 14px;
  color: #000000;
}

.box-wrapper-popup .price-popup {
  font-size: 4.5em;
  font-weight: 900;
  display: block;
  text-align: end;
  padding-left: 10px;
  width: 100%;
  /* margin: 20px auto 0; */
  position: relative;
  font-family: "Roboto";
  padding-bottom: 1px;
  margin-top: 10px;
  /* margin-right: 43px; */
  padding-right: 70px;
  color: #ff9100;
}

.price-popup {
  font-size: 4.5em;
  /* font-weight: 900; */
  display: block;
  text-align: start;
  padding-left: 10px;
  width: 100%;
  /* margin: 20px auto 0; */
  position: relative;
  font-family: unset;
  padding-bottom: 1px;
  margin-top: 10px;
  /* margin-right: 43px; */
  padding-right: 70px;
  color: #eb3592;
  font-family: "Open Sans";
}

.box-content-popup {
  height: 200px;
  display: flex;
  top: 90px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.close-menu-popup {
  align-items: center;
  text-align: right;
  justify-content: end;
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
  z-index: 999;
  position: absolute;
  /* padding-right: 25px; */
  top: 1px;
  left: 97%;
  color: #ff9100 !important;
}

@media only screen and (max-width: 900px) {
  .box-wrapper {
    width: 100%;
  }
}

.box-wrapper img {
  /*display: flex;*/

  height: 200px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .box-wrapper img {
    width: 50% !important;
  }

  .close-menu-popup {
    top: 8px;
    left: 90%;
  }
}

.box-wrapper .success {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 999;
}

.expanded .box-wrapper .success {
  opacity: 1;
  transition: opacity 0.3s 0.6s;
}

.box-wrapper .success:before,
.box-wrapper .success:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
}

.box-wrapper .success:before {
  width: 40px;
  height: 20px;
  transform: rotate(45deg);
}

.box-wrapper .success:after {
  width: 88px;
  height: 20px;
  transform: rotate(-45deg);
  top: -16px;
  left: 8px;
}

.titlerr {
  font-size: 1.3em;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 10px;
  font-family: "Open Sans";
  color: #eb3592;
  padding-top: 9px;
  padding-left: 14px;
}

.desc {
  font-family: "Merienda";
  font-size: 12px;
  width: 85%;
  color: #000000;
  padding-left: 14px;
}

@media only screen and (max-width: 900px) {
  .desc {
    font-size: 0.78em;
    width: 100%;
  }
}

.box-content {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  z-index: 1;
}

.box-content:before {
  display: none !important;
  content: "";
  width: 200%;
  height: 100px;
  position: absolute;
  display: block;
  background-color: #fff;
  transform: rotate(-8deg);
  top: -50px;
  left: -10%;
  z-index: -1;
}

.box-content .buy {
  display: block;
  top: -200px;
  right: 294px;
  z-index: 2;
  width: 10px;
  height: -164px;
  position: absolute;
}

.popupconthead {
  padding: 5%;
}

.close {
  align-items: center;
  text-align: right;
  padding-right: 25px;
  padding-bottom: 5px;
}

.popupconthead h3 {
  padding-left: 2%;
  color: #000000;
  text-transform: uppercase;
  font-size: 32px;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
}

@media only screen and (max-width: 900px) {
  .popupconthead h3 {
    font-size: 22px;
    text-shadow: -1px 1px 0px #000000;
    color: #000000;
  }
}

.popupconthead p {
  padding-left: 2%;
  color: black;
  font-weight: bold;
  font-family: georgia;
}

.product-pop {
  border-radius: 50px;
}

.popup-content {
  background: linear-gradient(
    90deg,
    #61e2cd 0%,
    #75f194 48%,
    #61cfe2 100%
  ) !important;
  border-radius: 15px !important;
  width: 70% !important;
}

@media only screen and (max-width: 900px) {
  .popup-content {
    width: 100% !important;
  }
}

.box-content .buy span {
  width: 70px;
  height: 70px;
  background: none;
  display: block;
  border-radius: 50%;
  position: absolute;
  font-size: 36px;
  color: #fff;
  transition: all 0.3s ease;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.box-content .buy span i {
  padding: 21px;
}

.expanded .box-content .buy span {
  width: 750px;
  height: 750px;
}

.expanded .box-content .buy span i {
  display: none;
}

.box-content .price {
  font-size: 30px;
  font-weight: 600;
  display: block;
  text-align: end;
  padding-left: 10px;
  width: 100%;
  /* margin: 20px auto 0; */
  position: relative;
  font-family: "Open Sans";
  padding-bottom: 0px;
  padding-right: 15px;
  color: #eb3592;
  width: fit-content;
  margin-left: auto;
}

.jjj:hover .box-content .price {
  background-color: #eb3592;
  color: white;
  padding: 0px 8px;
  border-radius: 8px;
  transform: scale(0.8);
  transition: transform 300ms ease-in-out;
}

/*.box-content .price:after,
.box-content .price:before {
  content: "";
  height: 1px;
  width: 50px;
  display: block;
  position: absolute;
  background-color: #ddd;
  top: 18px;
}
.box-content .price:before {
  left: -50px;
}
.box-content .price:after {
  right: -50px;
}*/
.box-content .footer ul {
  list-style: none;
  padding: 0;
  margin: 1em 0;
}

.box-content .footer ul li {
  color: #efd829;
}

.imgpopupp {
  width: 620px;
  height: 450px;
  border-radius: 25px;
}

.product-buttonn {
  border: none;
  background: #06d6a0;
  padding: 2%;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: georgia;
}

.vegnon {
  background: none;
  margin-top: 129px;
  margin-left: 289px;
  height: 182px !important;
  width: 19px !important;
  /* height: 0px !important; */
  padding-top: 89px;
  padding-bottom: 75px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
}

.vegnon:hover {
  -webkit-transform: scale(2);
  transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2); /* IE 9 */
  -webkit-transform: scale(1.2, 1.2);
}

.jjj {
  transition: transform 300ms ease-in-out;
}

.jjj:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 900px) {
  .vegnon {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box-wrapper-popup {
    flex-direction: column;
    height: 560px;
  }
  .box-content-popup {
    top: 0px;
  }
  .titlerr-popup {
    font-size: 25px;
  }
  .desc-popup {
    font-size: 16px;
  }
  .box-wrapper-popup .price-popup {
    text-align: start;
    margin-top: 0px;
    font-size: 3rem;
  }

  .titlerr {
    font-size: 18px;
  }
  .box-content .price {
    font-size: 20px;
  }

  .price-popup {
    font-size: 3.5rem;
  }
}
