/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.vision-part {
  padding: 50px;
  background: #fde6e7a4;
}

.vision-items {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.how-image {
  width: 100px;
  height: 80px;
  border-radius: 16px;
  margin-right: auto;
  margin-left: 30px;
  margin-bottom: 30px;
}

.how-content {
  margin-left: 2.5%;
  text-align: left;
}

.vision-heading {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 18px;
}

.vision-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.vision-item {
  cursor: pointer;
  font-size: 42px;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  place-items: center;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .vision-part {
    padding: 50px 18rem;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .vision-item {
    width: 32%;
    margin: 0px 1%;
  }
}

/* -------------********************************---------------- 
                   LARGE SCREEN - Max 1236px 
--------------- ********************************---------------- */

@media only screen and (max-width: 1236px) {
  .vision-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    place-items: center;
  }

  .vision-content {
    margin-top: 20px;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 768px) {
  .vision-items {
    display: flex;
    flex-direction: column;
  }
  .vision-item {
    width: 90%;
    margin: 0px auto;
    margin-bottom: 30px;
  }

  .vision-part {
    padding: 50px 15px;
  }

  .how-image {
    margin-left: 0px;
  }
}
