.pop {
  width: 100%;
}

.imgr {
  margin-bottom: 7%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
@media screen and (max-width: 768px) {
  .pop {
    width: 100%;
    height: 50%;
  }
}
.imgrr {
  position: relative;
  width: 40%;
  right: 30px;
  top: -110px;
  z-index: 2;
}
.hhh {
  font-weight: bold;
  font-weight: 500px;
  color: #eb3592;
  font-family: "Open Sans";
}

.pop-linkk {
  text-align: center;
}
.ppp {
  text-align: center;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .ppp {
    padding-left: 4%;
    align-items: center;
  }
}
.column {
  float: left;
  width: 33.33%;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  margin-bottom: 10px;
}
.column:hover,
.column:focus,
.column:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
a:hover {
  text-decoration: none;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none;
}
/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}
.popimg {
  width: 100%;
  padding: 10%;
  height: 80%;
  padding-bottom: 5%;
  box-shadow: 0px 0px 4px #eb3592;
  border-radius: 80px;
  border-bottom-right-radius: 0px;
  margin-bottom: 10px;
}

.poptxt {
  text-align: center;
  padding-top: 2%;
  font-size: 20px;
  color: #eb3592;
  text-transform: none;
  text-decoration: none;
  font-family: "Open Sans";
}

.spinner-wheel {
  width: 100px;
  height: 100px;
  transform: scale(1.2);
}

.spinner-wheel-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  padding: 10px;
  border-radius: 8px;
  background-color: #fde6e7;
}

.spinner-btn {
  margin: auto !important;
}

.spin-btn-text {
  font-family: "Open Sans";
  color: #eb3592;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 0px;
}
