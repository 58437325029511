.kungfuuu {
  background-color: #f8f7f5;
  height: 100vh;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .kungfuuu {
    height: 95vh;
    width: 100%;
  }
}
.kung {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;
}
@media only screen and (max-width: 768px) {
  .kung {
    height: 100vh;
    width: 100%;
  }
}

.bb {
  width: 12%;
  display: initial;
}
@media only screen and (max-width: 768px) {
  .bb {
    width: 50%;
  }
}
.bg {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  height: 100vh;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 20px;
}

@media screen and (min-width: 1910px) {
  .bg {
    width: 100%;
    margin-top: 0 !important;
    justify-content: center;
  }
}
@media only screen and (max-width: 768px) {
  .bg {
    margin-top: 0%;
    top: 53%;
    width: 100vw;
    height: 94vh;
  }
}

.bgtext {
  color: white;
  font-weight: bold;
  padding: 20px;
  margin-top: 9%;
}
@media only screen and (max-width: 768px) {
  .bgtext {
    margin-top: 25%;
    padding: 10px;
  }
}

.bord {
  border-bottom: 4px solid white;
  border-top: 4px solid white;
  font-family: "OPEN SANS";
  font-size: 30px;
  font-weight: bold;
  font-weight: 600;
  line-height: 25px;
  padding: 30px 0;
  margin: 0 auto;
  text-transform: uppercase;
  max-width: 830px;
  position: relative;
  z-index: 2;
  height: 101%;
  width: 100%;
  cursor: default;
  background: transparent;
}
@media only screen and (max-width: 768px) {
  .bord {
    font-size: 25px;
    height: 101%;
    width: 70%;
    line-height: 45px;
    padding: 10px 0;
    margin-top: 20px !important;
  }
}
.texttt {
  font-size: 9rem;
  font-weight: bolder;
  font-family: "Akronim" !important;
  text-shadow: -2px 2px #eb3592;
  cursor: default;
}
.colr {
  color: #eb3592;
  padding-right: 6%;
  background: #eb3592;
  background: linear-gradient(81deg, #eb3592 20%, white 50%, white 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 768px) {
  .colr {
    padding-top: 10%;
    flex-wrap: nowrap;
  }
}
.colrrr {
  color: #eb3592;
  padding-right: 6%;
  background: #eb3592;
  background: linear-gradient(81deg, #eb3592 20%, white 50%, white 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 768px) {
  .colrrr {
    flex-wrap: nowrap;
  }
}
.colrr {
  color: #eb3592;
  padding-right: 6%;
  background: #eb3592;
  background: linear-gradient(81deg, #eb3592 20%, white 50%, white 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 768px) {
  .colrr {
    padding-top: 10%;
    flex-wrap: nowrap;
  }
}
@media only screen and (max-width: 768px) {
  .texttt {
    font-size: 42px;
  }
}
