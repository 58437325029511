.headings {
  color: #eb3592;
  margin-bottom: 2%;
  font-family: "Open Sans";
}

.section .section-center {
  position: relative;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#booking {
  font-family: "Raleway", sans-serif;
}

.booking-form {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: auto;
  overflow: hidden;

  z-index: 20;
}

.booking-form::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  z-index: -1;
}

.form-label {
  position: relative;
  font-size: 16px;
}

.booking-form .form-group {
  height: 31%;
  border: 2px #000 solid;
}

.booking-form select.form-control + .select-arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 32px;
  height: 32px;
  text-align: center;
  pointer-events: none;
  color: rgba(7, 0, 0, 0.5);
  font-size: 15px;
}

.booking-form select.form-control + .select-arrow:after {
  content: "\279C";
  display: block;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.booking-form select.form-control option {
  color: #000;
}

.booking-form .submit-btn {
  color: #fff;
  background-color: #e35e0a;
  font-weight: 700;
  height: 60px;
  padding: 10px 30px;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1.3px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

#cont {
  background-color: #f8f9fa;
  -moz-box-shadow: 20px 10px 16px #000000;
  -webkit-box-shadow: 10px 10px 16px #000000;
  /*box-shadow: 20px 20px 28px #000000; */
  width: 60%;
  padding: 32px;
  margin-bottom: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px;
  margin-top: 40px;
}
@media only screen and (max-width: 600px) {
  #cont {
    padding-top: 10%;
    width: 100%;
  }
}

.gapp {
  background-color: #fff;
  padding-top: 5%;
}

@media only screen and (max-width: 1024px) {
  .gapp {
    padding-top: 7%;
  }
}

@media only screen and (max-width: 1280px) {
  .gapp {
    padding-top: 12%;
  }
}

.label-head {
  color: #000000;
  font-weight: 400;
  font-family: "Open Sans";
}
.event-submit {
  width: 730px;
}
@media only screen and (max-width: 900px) {
  .event-submit {
    width: 100% !important;
  }
}
/*============KK code========== */
.form-header {
  text-align: center;
}

.textarea-width {
  width: 100%;
  border: 1px solid black !important;
  font-weight: 400;
}
.textarea-width:focus {
  /* outline: 1px solid orange !important; */
  border: none !important;
}

.label-width {
  width: 100%;
}

label {
  width: 100% !important;
}

input {
  width: 100% !important;
  font-weight: 400 !important;
  border: 1px solid black !important;
  border-radius: 6px !important;
  margin-top: 5px !important;
  background: transparent;
}

input:focus {
  /* outline: 1px solid orange !important; */
  border: none !important;
}

select {
  width: 100% !important;
  border: 1px solid black !important;
  margin-top: 5px !important;
}
select:focus {
  /* outline: 1px solid orange !important; */
  border: none !important;
}
.input-transparent-bg {
  background-color: transparent !important;
}
.input-transparent-bg:focus {
  border: none !important;
}

.fade-up input::placeholder {
  font-family: "Open Sans" !important;
  font-size: 45px;
}

.login-input1::placeholder {
  font-family: "Open Sans";
  font-size: 14px;
}

.login-input1 {
  font-family: "Open Sans";
}
