.react-tabs1 {
  border-top: #ffa500fa solid 2px;
  display: flex;

  width: 100%;
  height: 100%;

  color: white;
  background: white;
}

.react-tab-div-nutrition {
  border-bottom: #ffa500fa solid 2px;
}

.react-tab-div-nutrition-inner {
  height: 700px;
  width: 100%;
  display: flex;
  padding: 30px 0px;
}

@media only screen and (min-width: 1910px) {
  .outdoor-part {
    padding: 40px 18rem;
  }
}

.store-title {
  font-family: "optima";
  text-transform: uppercase;
  font-size: 24px;
}

.store-heading1 {
  font-size: 64px;
  text-align: left;
  font-family: optima;
  font-weight: bold;

  color: #ffa500fa;
}
.store-content1 {
  font-size: 18px;
  text-align: left;
  align-items: center;
  font-family: optima;
  margin-top: 0px;
  margin-bottom: 18px;
  color: #000000fa;
  line-height: 31px;
}

.nutrition-hero-btn {
  cursor: pointer;
  text-decoration: underline;
  font-family: "Optima";
  color: #00aaaa;
}

.event-heading {
  font-size: 34px;
  color: #eb3592;
  text-align: left;
  font-family: "Open Sans";
  font-weight: bold;
  padding: 20px;
}

.event-content {
  color: black;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  font-family: "Open Sans";
  padding: 20px;
  text-align: left;
}

.enent-image {
  height: 200px !important;
  width: 100px !important;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  padding: 0;
  color: white;
  /* background: #3c3e43; */
  width: 25%;
  height: 800px !important;
  overflow: scroll;
}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  font-family: georgia;

  font-size: 23px;
  padding: 12px 6px;
  margin-right: 10%;
  cursor: pointer;
  color: #000000;
}
.react-tabs__tab:hover {
  color: #eb3592;
}

.react-tabs__tab--selected {
  border-left: 8px solid #eb3592;
  color: #eb3592;
  font-size: 23px;
}

.react-tabs__tab-panel {
  display: none;
  width: 70%;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
  padding-top: 21px;
  border: 1px solid white;
  border-bottom: 0px;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
}

.react-tabs__tab--selected {
  padding-left: 21px;
  padding-top: 21px;
  font-size: 23px;
  border-top: 1px solid white !important;
}

.react-tabs__tab--selected:focus {
  border: 0px solid black;
  outline: 0px solid black;
  border-left: 5px solid #eb3592;
}

.contentfulll1 {
  flex-wrap: nowrap !important;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.panel-content1 {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nutrition-hero-part {
  display: flex;
  margin: 90px 70px 40px;
}
.nutrition-hero-left {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
}

@media only screen and (min-width: 1910px) {
  .nutrition-hero-part {
    margin: 50px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .react-tabs {
    flex-direction: column;
  }
  .react-tabs__tab-list {
    width: 90%;
    text-align: center;
    font-size: 32px !important;
    height: 220px !important;
    margin: 20px;
  }
  .react-tabs__tab-panel {
    width: 100%;
  }
  .store-content {
    font-size: 20px;
    text-align: center;
    align-items: center;
    font-family: optima;
    margin-top: 12px;
    margin-bottom: 18px;
    color: #000000fa;
    width: 90%;
    margin-left: 5%;
  }
}
