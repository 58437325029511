@media all {
  .biro-component-listing-image {
    background: linear-gradient(#f8f7f5);
    background-color: #f8f7f5;
    padding: 30px 0;
  }
}

@media (min-width: 768px) {
  .biro-component-listing-image {
    padding: 70px 0;
    background-color: #f8f7f5;
  }
}

@media all {
  .item-blog {
    margin-bottom: 45px;
  }
}

@media all {
  .item-blog:last-child {
    margin-bottom: 0;
  }
}

.col-image {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.col-text {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media (min-width: 992px) {
  .col-image {
    padding-right: 0;
  }
}

@media all {
  .col-text {
    padding-right: 38px;
  }
}

@media (min-width: 992px) {
  .col-text {
    padding-left: 0;
  }
}

.text-wrapper {
  background: linear-gradient(
    90deg,
    #fef2f3 0%,
    #fde6e7 43%,
    #fde6e7 69%,
    #fef2f3 100%
  ) !important;
  height: 270px;
  padding: 37px 63px 31px 33px;
  position: relative;
}

@media (max-width: 768px) {
  .text-wrapper {
    height: 450px;
  }
}

@media all {
  img {
    vertical-align: middle;
    height: auto;
    max-width: 100%;
  }
}

@media all {
  .wp-post-image {
    width: 100%;
    height: 270px !important;
  }
}

@media all {
  .preheading {
    font-family: "Open Sans";
    font-size: 25px;
    color: #eb3592;
    font-weight: 700;
    line-height: 1.063;
  }
}

@media all {
  .preheading:after {
    background-color: #eb3592;
    content: "";
    display: block;
    height: 2px;
    margin: 5px 0;
    width: 50px;
  }
}

@media all {
  .title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 22px;
    font-weight: bold;
    font-weight: georgia;
    line-height: 1.143;
    margin-bottom: 10px;
    font-family: "Open Sans";
  }
}

@media all {
  .teaser {
    font-size: 14px;
    line-height: 26px;
    padding-top: 0px;
    font-family: "Open Sans";
  }
}

@media only screen and (max-width: 767px) {
  .teaser {
    padding-top: 30px;
  }
}

@media all {
  .arrow-circle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: #2a3535;
    border-radius: 50%;
    bottom: 25px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 46px;
    justify-content: center;
    position: absolute;
    right: -23px;
    width: 46px;
    z-index: 2;
  }
}

@media all {
  .tags {
    margin-top: 25px;
  }
}

@media all {
  svg {
    vertical-align: middle;
    overflow: hidden;
    height: auto;
    max-width: 100%;
  }
}

@media all {
  .arrow-circle svg {
    height: auto;
    width: 16px;
  }
}

@media all {
  .tag {
    border: 1px solid #2a3535;
    display: inline-block;
    font-family: pressura, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1;
    padding: 5px 8px;
  }
}

@media all {
  .tag:nth-child(n + 2) {
    margin-left: -1px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blog-roll {
  transition: transform 300ms ease-in-out;
  margin-bottom: 30px;
}

.blog-roll:hover {
  transform: scale(1.02);
  box-shadow: 1px 6px 20px 0px grey;
}
