/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.gold-product-component {
  display: flex;
  flex-direction: row;
  padding: 50px 5rem;
  gap: 50px;
}

.gold-product-component1 {
  display: flex;
  flex-direction: row;
  padding: 50px 5rem;
  gap: 50px;
  background-attachment: fixed;
}

.gold-product-component .main-title {
  text-align: left;
  margin-bottom: 0px;
}

.gold-product-component1 .main-title {
  text-align: left;
  margin-bottom: 0px;
}

.gold-product-component-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4%;
}

.gold-product-component-image-div {
  display: flex;
  width: 50%;
}

.gold-product-component-image {
  z-index: 2;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 0px 2px grey;
}

.gold-product-component-image1 {
  z-index: 2;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 0px 2px grey;
  /* margin-left: 15px; */
  width: 100%;
}

.about-us-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .gold-product-component {
    padding: 50px 19rem;
  }
  .gold-product-component1 {
    padding: 50px 19rem;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .gold-product-component {
    flex-direction: column;
    width: 100%;
    padding: 50px 30px;
  }
  .gold-product-component1 {
    flex-direction: column;
    width: 100%;
    padding: 50px 30px;
  }
  .gold-product-component-content {
    width: 100%;
  }

  .gold-product-component-image-div {
    width: 100%;
  }
  .gold-product-component-image {
    width: 100%;
    height: 330px;
  }
  .gold-product-component-image1 {
    width: 100%;
    height: 330px;
  }
  .bg-div {
    width: 84%;
    height: 330px;
  }
  .bg-div1 {
    width: 85%;
    height: 330px;
  }
  .comp2-content {
    order: 1;
  }
  .comp2-image {
    order: 2;
  }
}
